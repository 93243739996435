import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { tap } from "rxjs/operators";
import { UtilityService } from "../services/utility.service";
import { LoaderService } from "../services/loader.service";
import { LOGIN , WELCOME} from "../constant/routes";
import { ApiConfig } from "../models/api.interface";
import { environment } from "src/environments/environment";
import * as uuid from "uuid";
import { PLACE_ORDER } from '../constant/urls';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private _utilityService: UtilityService,
    private _loaderService: LoaderService,
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = {};
    const token = this._utilityService.getAuthToken();
    // const firebaseToken = this._utilityService.getFirebaseToken();
    let config: ApiConfig;
    config = JSON.parse(request.headers.get("config"));
    if (token) {
      headers["authorization"] = "Bearer " + token;
    }
    const fireDeveiceToken = localStorage.getItem("dtk");
    if (!localStorage.getItem(environment.guestKey)) {
      const guestUId = uuid.v4();
      localStorage.setItem(environment.guestKey, guestUId);
    }
    const customHeader = {
      devicedetails: {
        // token: firebaseToken ? firebaseToken : "",
        deviceId: localStorage.getItem(environment.guestKey),
        deviceType: 2,
        deviceToken: fireDeveiceToken ? fireDeveiceToken : "hard_coded_token",
      },
      offset: new Date().getTimezoneOffset(),
    };
    if (customHeader) {
      if (customHeader.devicedetails) {
        headers["devicedetails"] = JSON.stringify(customHeader.devicedetails);
        // headers["offset"] = JSON.stringify(new Date().getTimezoneOffset());
        headers["offset"] = "+19990";

        headers["api_key"] = environment.API_KEY;
      }
    }

    if (!navigator.onLine) {
      const error = new HttpErrorResponse({
        error: {
          httpCode: 510,
          statusCode: 510,
          // message: TranslateService.data.NO_INTERNET,
        },
      });
      if (!config.skipErrorPopup) {
        // this._utilityService.errorAlert(error);
      }
      return throwError(error);
    }

    request.headers.delete("config");
    request.headers.delete("skipHeaders");
    request = request.clone({
      setHeaders: headers,
    });
    if(request.url.match(PLACE_ORDER)){
      this._loaderService.orderLoader.next(true);
    }else{
      this._loaderService.loader.next(true);
    }
    // if (config && config.showLoader) {
    
    // }

    return next.handle(request).pipe(
      tap(
        (data) => {
          if (data instanceof HttpResponse) {
            // if (config && config.showLoader) {
            this._loaderService.loader.next(false);
            this._loaderService.orderLoader.next(false);
            // }
          }
        },
        (err: any) => {
          // if (config && config.showLoader) {
          this._loaderService.loader.next(false);
          this._loaderService.orderLoader.next(false);
          // }
          if (err instanceof HttpErrorResponse) {
            if (config && !config.skipErrorPopup) {
              // this._utilityService.errorAlert(err);
            }
            if (
              err.status === 401 ||
              err.status === 440 ||
              err.status === 403
            ) {
              this._utilityService.clearStorage();
              this.router.navigate([WELCOME.fullUrl]);
              this._utilityService.showAlert("Your Session Expired. Please login again to use the awesome contents!");
            }
          }
        }
      )
    );
  }
}
