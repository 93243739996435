import { Component } from "@angular/core";
import { LoaderService } from "./services/loader.service";
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from "@angular/router";
import { UtilityService } from "./services/utility.service";
import { environment } from "src/environments/environment";
import { IMyCurrentLocation } from "./constant/interface";
import { UserProfileService } from "./services/user-profile.service";
import { HOME } from "./constant/routes";
import * as uuid from "uuid";
import { InternetService } from "./services/internet.service";
import { WebSidebarService } from "./services/web-sidebar.service";
import { SwUpdate, SwPush } from "@angular/service-worker";
import { IPopupData } from "./models/popup";
import { MessagingService } from "./services/messaging.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";

declare var gtag: any;
declare var window:any
window.mapLoaded = () => {
  console.log("Map loaded *********")
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  showSplash: boolean = true;
  loaderCount = 0;
  loading: boolean = false;
  iscookieActivated: any = false;
  noInternet: boolean = false;
  isNoInternet: boolean = false;
  message;
  constructor(
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private router: Router,
    private utility: UtilityService,
    private userProfilerService: UserProfileService,
    private route: ActivatedRoute,
    private internetService: InternetService,
    private webSidebar: WebSidebarService,
    updates: SwUpdate,
    private gaService: GoogleAnalyticsService
  ) {
    const cookiesValue = 1;
    if (
      !localStorage.getItem("clearCookiesValue") ||
      parseInt(localStorage.getItem("clearCookiesValue")) > cookiesValue
    ) {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      localStorage.setItem("clearCookiesValue", cookiesValue.toString());
    }
    // console.log(this.route.snapshot["_routerState"].url);
    this.gaService.init();
    this.utility.firstLaunch = true;
    if (!sessionStorage.getItem("firstLaunch")) {
      sessionStorage.setItem("firstLaunch", "true");
    }
    this.setGuesUIDToken();
    updates.available.subscribe((data) => {
      this.checkForUpdates();
    });
    this.loaderService.loader.subscribe((state) => {
      setTimeout(() => {
        this.loading = state;
      }, 0);
    });

    this.internetService.online$.subscribe((online) => {
      if (!online) {
        this.isNoInternet = true;
      } else {
        this.isNoInternet = false;
      }
    });

    setTimeout(() => {
      if (this.isNoInternet == true) {
        this.noInternet == true;
      } else {
        this.noInternet == false;
      }
    }, 0);
  }

  testNotification() {
    const userId = "user001";
    this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  }

  checkForUpdates() {
    const dialogData: IPopupData = {
      hideCancelButton: true,
      confirmButtonText: "Refresh",
      message:
        "We just updated our website, please refresh to get the latest experience",
    };
    const dialogRef = this.utility.openDialog(dialogData);
    dialogRef.subscribe((data) => {
      if (data) {
        window.location.reload();
      }
    });
  }
  ngOnInit() {
    // setTimeout(() => {
    //   this.showSplash = !this.showSplash;
    // }, 2000);
    // this.fetchUsersLocation();

    var script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.mapAPIKey}&libraries=places&callback=mapLoaded`;
    // script.async = true;
    document.body.appendChild(script);
    this.loaderService.loader.subscribe((state) => {
      setTimeout(() => {
        this.loaderCount = this.loaderCount + (state ? 1 : -1);
        this.loaderCount = this.loaderCount < 0 ? 0 : this.loaderCount;
      });
    });

    // this.router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.loaderService.loader.next(true);
    //   } else if (event instanceof NavigationEnd) {
    //     this.loaderService.loader.next(false);
    //   } else if (event instanceof NavigationError) {
    //     this.loaderService.loader.next(false);
    //   } else if (event instanceof NavigationCancel) {
    //     this.loaderService.loader.next(false);
    //   }
    // });
    this.testNotification();
  }

  setGuesUIDToken() {
    //set Guest token
    if (!localStorage.getItem(environment.guestKey)) {
      const guestUId = uuid.v4();
      localStorage.setItem(environment.guestKey, guestUId);
    }
  }

  fetchUsersLocation() {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${environment.googleKey}`
          )
            .then((res) => res.json())
            .then((response) => {
              this.gaService.event("USER_LOCATION_ALLOWED", {});
              console.log(response.results, "response.resultsresponse.results");

              const maxAddress = response.results;
              if (maxAddress.length) {
                maxAddress.reduce((prev, curr) => {
                  return prev.address_components.length >
                    curr.address_components.length
                    ? prev
                    : curr;
                });
              } else {
                return;
              }

              const results = maxAddress.address_components;

              const locationObj: any = {
                city: this.utility.findResult(results, [
                  "administrative_area_level_2",
                  "political",
                ]),
                state: this.utility.findResult(results, [
                  "administrative_area_level_1",
                  "political",
                ]),
                locality: this.utility.findResult(results, [
                  "locality",
                  "political",
                ]),
                subLocality: this.utility.findResult(results, [
                  "political",
                  "sublocality",
                  "sublocality_level_1",
                ]),
                country: this.utility.findResult(results, [
                  "country",
                  "political",
                ]),
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                address: maxAddress.formatted_address,
                pincode: this.utility.findResult(results, ["postal_code"]),
              };

              // latitude: position.coords.latitude,
              // longitude: position.coords.longitude,
              // address: maxAddress.formatted_address,
              // console.log("User's Location Info: ", response);
              // this.userProfilerService.profileDetail.next({
              //   ...locationObj,
              // });
            })
            .catch((status) => {
              this.gaService.event("USER_LOCATION_DENIED", {});
              console.error("Request failed.  Returned status of", status);
              // this.utility.showAlert("Please selecte location to proceed !");
              this.router.navigate([HOME.fullUrl]);
            });
        },
        (error) => {
          console.error(error);
          // this.utility.showAlert("Please selecte location to proceed !");
          // this.router.navigate([HOME.fullUrl]);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  tryAgain() {
    window.location.reload();
  }
}
