import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderNotificationComponent } from './order-notification.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [OrderNotificationComponent],
  imports: [
    CommonModule,
    MatIconModule,

  ],
  exports: [OrderNotificationComponent]
})
export class OrderNotificationModule { }
