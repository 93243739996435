import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { HttpService } from "./http.service";
import { FCM_TOKEN_API, GET_USER_DETAIL, LOGOUT } from "../constant/urls";
import { FileUploadService } from "./file-upload.service";
import { UtilityService } from "./utility.service";
import { GuestLoginData } from "../constant/response.interface";
import { MessagingService } from "./messaging.service";

@Injectable({
  providedIn: "root",
})
export class UserProfileService {
  profileRefresh = new BehaviorSubject<any>(null);

  profileDetail = new BehaviorSubject<any>(null);
  profileData: GuestLoginData;
  socialUserData: any;
  temporaryLocation = new ReplaySubject<any>(1);

  currentLocationDetail = new BehaviorSubject<any>(null);
  currentLocationdata: any;

  constructor(
    private http: HttpService,
    private fileUploadService: FileUploadService,
    private utilityService: UtilityService,
    private _messagingService: MessagingService
  ) {}

  async getProfileDetail(refresh = false) {
    const token = this.utilityService.getAuthToken();
    if (!token) {
      return null;
    }
    try {
      if (this.profileData && !refresh) {
        return this.profileData;
      }
      const response = await this.http.get(GET_USER_DETAIL).toPromise();
      // console.log('user prooododod',response)
      this.profileData = response.data;
      this.profileDetail.next({ ...this.profileData });
      // this.profileRefresh.next(true);
      this.onUpdateDeviceToken(this.profileData._id);
      return { ...this.profileData };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  onUpdateDeviceToken(userId: string) {
    const token = localStorage.getItem("ftoken");
    if (token) {
      this._messagingService.updateToken(userId, token);
      this.http.patch(FCM_TOKEN_API, { deviceToken: token }).toPromise();
    }
  }

  logOut() {
    try {
      return this.http.put(LOGOUT, {}).toPromise();
    } catch (error) {
      return null;
    }
  }

  async getUserCurrentLocation(refresh = false) {
    try {
      if (this.currentLocationdata && !refresh) {
        return this.currentLocationdata;
      }
      const response = await this.utilityService.getCurrentLocation();
      this.currentLocationdata = response;
      this.currentLocationDetail.next({ ...this.profileData });
      return { ...this.currentLocationdata };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  uploadFiles(files: File[]) {
    return this.fileUploadService.uploadMultipleFiles(files);
  }
  // updateFireBaseToken(body) {
  //   return this.http.put(UPDATE_FIREBASE_TOKE, body);
  // }

  // updateUserProfile(body) {
  //   return this.http
  //     .put(UPDATE_PROFILE_DETAIL, body, { showLoader: true })
  //     .toPromise();
  // }
}
