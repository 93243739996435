import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItemDetailWebComponent } from "./item-detail-web.component";
import { MenuFoodCardService } from "../service/menu-food-card.service";
import { MenuFoodCardModule } from "../menu-food-card.module";

@NgModule({
  declarations: [ItemDetailWebComponent],
  imports: [CommonModule, MenuFoodCardModule],
  providers: [MenuFoodCardService],
  entryComponents: [ItemDetailWebComponent],
})
export class ItemDetailWebModule {}
