import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class WebSidebarService {
  $sidebarState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchLocationChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fromAddAddress: boolean = false;
  constructor(private router: Router) {}

  openSidebar(): void {
    this.$sidebarState.next(true);
  }

  closeSidebar(): void {
    this.$sidebarState.next(false);
    this.router.navigate([{ outlets: { web_sidebar: null } }], {
      skipLocationChange: true,

      // relativeTo: this.route.parent,
    });
  }
}
