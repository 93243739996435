import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { TokenInterceptor } from "./Interceptors/token.interceptor";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { LoaderComponent } from "./modules/common/components/loader/loader.component";
import { ConfirmationModalComponent } from "./modules/common/components/confirmation-modal/confirmation-modal.component";
import {
  MatBottomSheetModule,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { NoInternetConnectionModule } from "./modules/features/no-internet-connection/no-internet-connection.module";
import { TechnicalErrorModule } from "./modules/features/common/technical-error/technical-error.module";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./services/messaging.service";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { ItemDetailWebComponent } from "./modules/common/modules/menu-food-card/item-detail-web/item-detail-web.component";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { MenuFoodCardService } from "./modules/common/modules/menu-food-card/service/menu-food-card.service";
import { ItemDetailWebModule } from "./modules/common/modules/menu-food-card/item-detail-web/item-detail-web.module";
import { OrderNotificationModule } from "./modules/common/modules/order-notification/order-notification.module";
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ConfirmationModalComponent,
    SplashScreenComponent,
    // ItemDetailWebComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy:"registerImmediately"
    }),
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    HttpClientModule,
    FormsModule,
    MatBottomSheetModule,

    // NoInternetConnectionModule,
    OrderNotificationModule,
    TechnicalErrorModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    ItemDetailWebModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    MessagingService,
    GoogleAnalyticsService,

    // { provide: MatBottomSheetRef, useValue: {} },
    // { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationModalComponent],
})
export class AppModule {}
