import { IRoute } from "../models/route.interface";

export const HOME: IRoute = {
  path: "",
  get fullUrl(): string {
    return `/`;
  },
};

export const WELCOME: IRoute = {
  path: "welcome",
  get fullUrl(): string {
    return `/welcome`;
  },
};

export const ACCOUNT: IRoute = {
  path: "account",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LOCATION_SEARCH: IRoute = {
  path: "location-search",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LOCATION_SEARCH_MAP: IRoute = {
  path: "location-search-map",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LOGIN: IRoute = {
  path: "login",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

export const PROFILE: IRoute = {
  path: "profile",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const PROFILE_DETAILS: IRoute = {
  path: "details",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};
export const PROFILE_SETTINGS: IRoute = {
  path: "settings",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const NOTIFICATIONS: IRoute = {
  path: "notification",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PROFILE_EDIT: IRoute = {
  path: "edit",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};
export const PROFILE_SAVED_ADDRESSES: IRoute = {
  path: "saved-address",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PROFILE_MY_ORDERS: IRoute = {
  path: "my-orders",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PROFILE_MY_OFFERS: IRoute = {
  path: "my-offers",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PROFILE_ORDER_SUMMARY: IRoute = {
  path: "order-summary",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PROFILE_CREATE: IRoute = {
  path: "create",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PRODUCT_LIST: IRoute = {
  path: "product-list",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const EXPLORE: IRoute = {
  path: "explore",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ORDER_SUCCESS: IRoute = {
  path: "delivered",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const EXPLORE_MENU: IRoute = {
  path: "explore-menu",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const CHECKOUT: IRoute = {
  path: "checkout",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const CART: IRoute = {
  path: "cart",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ADD_ADDRESS: IRoute = {
  path: "add-address",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const APPLY_COUPON: IRoute = {
  path: "apply-coupon",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ORDER_PLACED: IRoute = {
  path: "order-placed",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const TRACK_ORDER: IRoute = {
  path: "track-order",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const PRODUCT_DETAIL: IRoute = {
  path: "product-detail",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const FREE_BIRYANI: IRoute = {
  path: "free-biryani",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LOYALTY_REWARDS: IRoute = {
  path: "loyalty-rewards",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const OFFERS: IRoute = {
  path: "offers",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const FAVOURITE: IRoute = {
  path: "favourite",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const SEARCH: IRoute = {
  path: "search",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const EMAIL_US: IRoute = {
  path: "email-us",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const MAP_LAUNCH: IRoute = {
  path: "map-launch",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const RATE_AND_REVIEW: IRoute = {
  path: "rate-and-review",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ORDER_PLACED_SUCCESSSFULLY: IRoute = {
  path: "success",
  get fullUrl(): string {
    return `/${CART.fullUrl}/${this.path}`;
  },
};

// preveous routs

export const SIGNUP: IRoute = {
  path: "signup",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const FORGOT_PASSWORD: IRoute = {
  path: "forgot-password",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const RESET_PASSWORD: IRoute = {
  path: "reset-password",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const CHANGE_PASSWORD: IRoute = {
  path: "change-password",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LINK_EXPIRED: IRoute = {
  path: "link-expired",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const CONTENT = {
  path: "content",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const NO_INTERNET_CONNECTION: IRoute = {
  path: "no-internet-connection",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const NOTIFICATION: IRoute = {
  path: "notification",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const PROPERTY: IRoute = {
  path: "property",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ALL_RATING: IRoute = {
  path: "all-rating",
  get fullUrl(): string {
    return `${PROPERTY.fullUrl}/${this.path}`;
  },
};

export const GUEST_USER_CHECKOUT: IRoute = {
  path: "guest-user-checkout",
  get fullUrl(): string {
    return `${PROPERTY.fullUrl}/${this.path}`;
  },
};

export const GUEST_USER_LOGIN = {
  path: "guest-login",
  fullUrl(propertyId, spaceId): string {
    return `${GUEST_USER_CHECKOUT.fullUrl}/${propertyId}/${spaceId}/${this.path}`;
  },
};
export const GUEST_USER_SIGNUP = {
  path: "guest-signup",
  fullUrl(propertyId, spaceId): string {
    return `${GUEST_USER_CHECKOUT.fullUrl}/${propertyId}/${spaceId}/${this.path}`;
  },
};

export const PROPERTY_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${PROPERTY.fullUrl}/${this.path}`;
  },
};

export const PROPERTY_DETAIL: IRoute = {
  path: "detail",
  get fullUrl(): string {
    return `${PROPERTY.fullUrl}/${this.path}`;
  },
};
export const PROPERTY_BOOKING: IRoute = {
  path: "booking",
  get fullUrl(): string {
    return `${PROPERTY.fullUrl}/${this.path}`;
  },
};

export const PROPERTY_ABANDONED_BOOKING_PAYMENT: IRoute = {
  path: "payment",
  get fullUrl(): string {
    return `${PROPERTY.fullUrl}/${this.path}`;
  },
};

export const PROPERTY_BOOKING_PAYMENT_SELECTION: IRoute = {
  path: "payment-selection",
  get fullUrl(): string {
    return `${PROPERTY.fullUrl}/${this.path}`;
  },
};

export const PAYMENT: IRoute = {
  path: "payment",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const PAYMENT_PREVIEW: IRoute = {
  path: "preview",
  get fullUrl(): string {
    return `${PAYMENT.fullUrl}/${this.path}`;
  },
};
export const WISHLIST: IRoute = {
  path: "wish-list",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const CHAT: IRoute = {
  path: "chat",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ACCOUNT_DETAILS: IRoute = {
  path: "detail",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const EDIT_COMPANY_PROFILE: IRoute = {
  path: "edit-company-profile",
  get fullUrl(): string {
    return `${ACCOUNT_DETAILS.fullUrl}/${this.path}`;
  },
};

export const BOOKING: IRoute = {
  path: "booking",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const CO_WORKING: IRoute = {
  path: "co-working-list",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const BOOKING_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${BOOKING.fullUrl}/${this.path}`;
  },
};

export const BOOKING_DETAILS: IRoute = {
  path: "detail",
  get fullUrl(): string {
    return `${BOOKING.fullUrl}/${this.path}`;
  },
};
export const COWORKER_LIST: IRoute = {
  path: "coworker-list",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const GIFT_CARDS: IRoute = {
  path: "gift-cards",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const GIFT_CARD_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${GIFT_CARDS.fullUrl}/${this.path}`;
  },
};

export const ADD_GIFT_CARD: IRoute = {
  path: "add",
  get fullUrl(): string {
    return `${GIFT_CARDS.fullUrl}/${this.path}`;
  },
};

export const USED_GIFT_CARD_LIST: IRoute = {
  path: "used-card",
  get fullUrl(): string {
    return `${GIFT_CARDS.fullUrl}/${this.path}`;
  },
};

export const GIFT_CARD_PAYMENT: IRoute = {
  path: "payment",
  get fullUrl(): string {
    return `${GIFT_CARDS.fullUrl}/${this.path}`;
  },
};

export const PAYMENTS: IRoute = {
  path: "payments",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PAYMENTS_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${PAYMENTS.fullUrl}/${this.path}`;
  },
};

export const SAVED_CARDS: IRoute = {
  path: "cards",
  get fullUrl(): string {
    return `${PAYMENTS.fullUrl}/${this.path}`;
  },
};

export const ADD_CARD: IRoute = {
  path: "add-cards",
  get fullUrl(): string {
    return `${PAYMENTS.fullUrl}/${this.path}`;
  },
};

export const SETTING: IRoute = {
  path: "setting",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const STATIC: IRoute = {
  path: "content",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const PRIVACY_POLICY: IRoute = {
  path: "privacy-policy",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};

export const TERM_CONDITION: IRoute = {
  path: "term-condition",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};
export const ABOUT_US: IRoute = {
  path: "how-it-works",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};

export const PAYMENT_POLICY: IRoute = {
  path: "payment-policy",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};

export const IMPRINT: IRoute = {
  path: "imprint",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};

export const REFUND_POLICY: IRoute = {
  path: "refund-policy",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};

export const FAQ: IRoute = {
  path: "faq",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};
export const OUR_STORY: IRoute = {
  path: "desk-now-story",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};
export const OUR_TEAM: IRoute = {
  path: "our-team",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};

export const CONTACT_US: IRoute = {
  path: "contact-us",
  get fullUrl(): string {
    return `${STATIC.fullUrl}/${this.path}`;
  },
};
export const COMPLETE_PROFILE: IRoute = {
  path: "complete-profile",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

//web routes

export const WEB_ACCOUNT: IRoute = {
  path: "web-account",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

export const WEB_LOCATION_SEARCH: IRoute = {
  path: "location-search",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

export const WEB_LOCATION_SEARCH_MAP: IRoute = {
  path: "location-search-map",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

export const WEB_LOGIN: IRoute = {
  path: "web-login",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

export const WEB_VERIFY_OTP: IRoute = {
  path: "web-verify-otp",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

export const WEB_CREATE_PROFILE: IRoute = {
  path: "web-create-profile",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

export const EDVO: IRoute = {
  path: "edvo",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const EDVO_DETAIL: IRoute = {
  path: "edvo-detail",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LINK: IRoute = {
  path: "link",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const DELETE_ACCOUNT: IRoute = {
  path: "delete-account",
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`
  }
}