<!-- <div *ngIf="showSplash" class="splash-screen-mobile">
  <app-splash-screen></app-splash-screen>
</div> -->
<!-- <div *ngIf="!showSplash"> -->
<div>
  <app-order-notification></app-order-notification>
  <ng-container *ngIf="!noInternet">
    <router-outlet></router-outlet>
    <div *ngIf="loading" class="loader">
      <div class="dot-typing"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="noInternet">
    <div class="technical-error">
      <!-- <figure>
        <img src="../assets/images/InternetConnection.png" alt="No Result Found" />
      </figure> -->
      <div class="content">
        <h3>OOPS, It seems you've lost Connection</h3>
        <p>
          Slow or no internet connection. Please check your connection or try
          again.
        </p>
      </div>
      <div class="change-location">
        <a (click)="tryAgain()">Retry</a>
      </div>
    </div>
  </ng-container>

  <div>
    <router-outlet name="web_sidebar"></router-outlet>
  </div>
</div>
