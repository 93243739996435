import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnicalErrorComponent } from './technical-error.component';



@NgModule({
  declarations: [TechnicalErrorComponent],
  imports: [
    CommonModule
  ],
  exports: [TechnicalErrorComponent]
})
export class TechnicalErrorModule { }
