// tslint:disable-next-line:no-namespace
export const LOGIN = "user/login";
export const REGISTERATION = "user/registeration";
export const SIGNUP = "user/signup";
export const RESEND_OTP = "user/resend-otp";
export const ACCOUNT_DEL_SEND_OTP = "user/send-otp";
export const ACCOUNT_DEL_VERIFY_OTP = (otp) => `user/account/${otp}`;
export const NOTIFICATION_LIST_API = "user/notification";
export const PUSH_NOTIFICATION_PUT = "user/notification/toggleNotification";
export const EMAIL_NOTIFICATION_PUT =
  "user/notification/toggleEmailNotification";
export const VERIFY_OTP = "user/verify-otp";
export const VERIFY_RESET_OTP = "user/verifyResetOtp";
export const RESEND_EMAIL = "user/verificationEmailLink";
export const FORGOT_EMAIL = "user/forgetPasswordEmail";
export const FORGOT_PHONE = "user/forgetPasswordPhonel";
export const RESET_PASSWORD = "user/resetPassword";
export const CHANGE_PASSWORD = "user/changePassword";
export const DELETE_ACCOUNT = "user/deleteAccount";
export const LOGOUT = "user/logout";
export const VALIDATE_TOKEN = "user/verifyResetToken";
// export const PROFILE_DETAIL = 'user/myProfile';
export const UPDATE_PROFILE_DETAIL = "user/updateProfile";
export const COMPANY_DETAIL = "user/companyDetail";
export const GET_ALL_COUNTRIES = "user/getAllCountries";
export const GET_ALL_STATES = (countryId) => `user/getAllStates/${countryId}`;
export const GET_ALL_CITIES = (stateId) => `user/getAllCities/${stateId}`;
export const GET_COUNTRIES = "user/getCountries";
export const GET_STATES = (countryId) => `user/getStates/${countryId}`;
export const GET_CITIES = (stateId) => `user/getCities/${stateId}`;
export const UPDATE_FIREBASE_TOKE = "user/deviceToken";

export const CHECK_USER_CHAT_STATUS = `user/chatRoom`;
export const CONTACT_LIST = "user/coworker/contactList";

export const GET_FAV_COUNTRIES = "user/property/favCountryListing";
export const GET_FAV_STATES = (countryId) =>
  `user/property/favStateListing/${countryId}`;
export const GET_FAV_CITIES = (stateId) =>
  `user/property/favCityListing/${stateId}`;
export const CMS_GET = "cms";
export const COMPLETE_PROFILE = "user/completeProfile";
export const CANCELLATION_REFUND = "user/property/bookingPolicy";

// export const UPDATE_COMPANY_DETAIL = "user/companyDetail";
export const SOCIAL_LOGIN = "user/socialLogin";
export const LINKEDIN_AUTH = "";
export const SEARCH_LOCATION = "user/property/getLocation";
export const CREATE_PROFILE = "user/create-profile";
export const DELETE_PROFILE = "user/delete-user";

//paradise urls
export const ADD_LOCATION_GUEST_USER = `user/guest-user`;
export const USER_REGISTRATION = `user/registeration`;
export const GET_USER_DETAIL = `user/profile`;
export const USER_SOCIAL_REGITERATION = `user/social-registeration`;
export const USER_GOOGLE_REGITERATION = `user/google-registeration`;
export const CHANGE_LOCATION_POST = "user/change-location";

export const USER_NOTIFICATION_TOGGLE = "user/notification/toggle";

// fromLogin 0 -> guest
// fromLogin 1-> after login
export const HOME_PAGE_GET = (restaurantId: string, fromLogin: number) =>
  `home?restaurantId=${restaurantId}&fromLogin=${fromLogin}`;

export const HOME_FIRST_VISIT_GET = (storeCode: string) => `restaurants/getRestaurantId?storeCode=${storeCode}`;  

export const FOOD_ITEMS_GET = (restaurantId: string, itemType?: 0 | 1) => {
  if (itemType) {
    return `restaurants/explore/menuScroll?restaurantId=${restaurantId}&itemType=${itemType}`;
  } else {
    return `restaurants/explore/menuScroll?restaurantId=${restaurantId}`;
  }
};

export const OFFERS_GET = (id) => `coupons?restaurantId=${id}`;
export const EDVO = (restaurantId: string) =>
  `coupons/edvo?restaurantId=${restaurantId}`;

export const EDVO_DETAIL = (restaurantId: string, edvoCode: string) =>
  `coupons/edvo/item?restaurantId=${restaurantId}&edvoCode=${edvoCode}`;

export const ADD_EDVO_POST = `coupons/edvo/addCart`;
export const PRODUCT_DETAIL_GET = (
  restaurantId: string,
  itemId: string,
  restaurantItemId: string,
  fromSearch: 0 | 1
) =>
  `items?itemId=${itemId}&restaurantItemId=${restaurantItemId}&restaurantId=${restaurantId}&fromSearch=${fromSearch}&page=1&limit=10`;

export const FAVOURITE_ITEM_GET = (
  restaurantId: string,
  page: number,
  limit: number
) =>
  `items/fav-item-list?restaurantId=${restaurantId}&page=${page}&limit=${limit}`;

export const ADD_ADDRESS_PUT = `user/add-delivery-address`;

export const VALIDATE_COUPON = (query) =>
  `coupons/validation?cartId=${query.cartId}&couponCode=${query.couponCode}&couponType=${query.couponType}&price=${query.price}&transactionApplicable=${query.transactionApplicable}&applicableOnFirstOrder=${query.applicableOnFirstOrder}`;

export const USER_ADDRESSES_GET = `user/get-addresses`;
export const CHANGE_DELIVERY_ADDRESS = `user/get-addresses`;
export const CHANGE_DEFAULT_ADDRESS_PATCH = `user/change-default-address`;
export const EDIT_ADDRESS_PUT = `user/edit-delivery-address`;
export const DELETE_ADDRESS = `user/delete-address`;
export const MY_ORDERS = `user/order/order-list`;
export const ORDER_DETAIL = `user/order/order-detail`;
// CART

export const CARTS = "carts/new";
export const CARTS_BILL_DATA_API = "carts/bill-data";
export const LOYALTY_POINTS_AVAILABLE = "loyalty/customer-balance";
export const ADD_ITEM_TO_CART = "carts";
export const FREE_BIRYANI = "items/free/biryani";

export const ADD_FAVOURITE_POST = `items/add-favourite`;
export const REMOVE_FAVOURITE_DELETE = `items/delete-favourite`;

export const SEARCH_ITEM_API = `items/search`;
export const DEV_SEARCH_ITEM_API = `user/web/item-search`;
export const RECENT_SEARCH_ITEM_API = `items/recent-search`;
export const COMPLETE_REDEMPTION_API = `loyalty/complete-redemption`;
export const LOYALTY_REFUND_API = `loyalty/loyalty-refund`;

export const ADD_RATINGS = `user/order/add-ratings`;
export const REVIEW_LIST_API = `user/order/review-list`;
export const REORDER = `carts/reorder-items`;

// export const PLACE_ORDER = `user/order/place`;
export const PLACE_ORDER = `user/order/new/place`;
export const CLEAR_CART = `carts/empty`;
export const TRACK_ORDER_STATUS = `delivery/track/status`;

export const FCM_TOKEN_API = `user/update-device-token`;

export const ORDERS_HOME = `home/order-details`;

export const CHECKOUT_CART_WEB_GET = (restaurantId: string) =>
  `user/web/carts/checkout?restaurantId=${restaurantId}`;

export const CANCEL_ORDER = `payment/cancel-order`;

//EDVO REMOVE
export const REMOVE_EDVO = `coupons/edvo/remove`;
export const POPULAR_ITEMS_GET = (id: string) =>
  `items/popular?restaurantId=${id}`;

export const USER_ADDRESSES_GET_NEW = (id: string) =>
  `user/get-addresses?restaurantId=${id}`;

export const EDVO_DETAIL_GET = (restaurantId, edvoCode) =>
  `coupons/edvo/criteria?restaurantId=${restaurantId}&edvoCode=${edvoCode}`;
