import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  CanLoad,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UtilityService } from "../../services/utility.service";
import { HttpService } from "../../services/http.service";
import { LOGIN, HOME, LOCATION_SEARCH } from "../../constant/routes";
import { VALIDATE_TOKEN } from "../../constant/urls";
import { UserProfileService } from "src/app/services/user-profile.service";
import { POPUP_MESSAGES } from "src/app/constant/messages";

@Injectable()
export class AccountGuard implements CanActivate, CanLoad {
  isGuestUser: any;
  profileDetail: any;
  constructor(
    private _router: Router,
    private _utilityService: UtilityService,
    private _http: HttpService,
    private userProfileService: UserProfileService
  ) {
    this.getProfileDetail();
  }

  navigate() {
    this._router.navigate([HOME.fullUrl]);
    return false;
  }

  async getProfileDetail() {
    this.profileDetail = await this.userProfileService.getProfileDetail();
    this.isGuestUser = this.profileDetail.isGuestUser;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // if (!this._utilityService.getAuthToken()) {
    //   let token = next.params.token;
    //   if (token) {
    //     return this.validateResetPasswordToken(token);
    //   } else {
    //     return true;
    //   }
    // }
    console.log(this.isGuestUser , 'IS GUEST USER')
    if (this.isGuestUser == 1 && this._utilityService.getAuthToken()) {
      return true;
    } else if(this._utilityService.getAuthToken()) {
      this.navigate();
      return false;
    }
    // return this.navigate();
    return true;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this._utilityService.getUserAddress()) {
      return true;
    }
    return this.navigate();
  }

  // validateResetPasswordToken(token) {
  //   return new Observable < boolean > ((observer) => {
  //     this._http.get(`${VALIDATE_TOKEN}/${token}`).subscribe(
  //       response => {
  //         observer.next(true);
  //         observer.complete();
  //       }, err => {
  //         this._router.navigate([LOGIN.fullUrl]);
  //         observer.next(false);
  //         observer.complete();
  //       }
  //     )
  //   });
  // }
}
