import { Component, OnInit, Inject } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductListService } from "src/app/modules/features/product-list/product-list.service";
import { UtilityService } from "src/app/services/utility.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import { CartMessangerService } from "src/app/services/cart-messanger-service.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
import { HOME } from "src/app/constant/routes";
import { MenuFoodCardService } from "../service/menu-food-card.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IPopupData } from "src/app/models/popup";

@Component({
  selector: "app-item-detail-web",
  templateUrl: "./item-detail-web.component.html",
  styleUrls: ["./item-detail-web.component.scss"],
})
export class ItemDetailWebComponent implements OnInit {
  productId: string = null;
  restaurantItemId: string = null;
  itemDetails: any = null;
  freeBiryani: any;
  freeBiryanis: any;
  isUserGuest: boolean = false;
  scrollLeftCount: number = 0;
  fromSearch: 0 | 1 = 0;
  sub: Subscription = new Subscription();
  restaurantOffline: boolean = false;
  profileDetail: any;
  showScrollRight: boolean = true;
  changeDone: boolean = false;
  parent: string = null;
  freebiryaniCount: number = 0;
  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private productListService: MenuFoodCardService,
    private utility: UtilityService,
    private profileService: UserProfileService,
    private cartMessangerService: CartMessangerService,
    private gaService: GoogleAnalyticsService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialogRef: MatDialogRef<ItemDetailWebComponent>
  ) {
    // this.productId = this.actRoute.snapshot.params.productId;
    // this.actRoute.params.subscribe(({ productId }) => {
    this.productId = data.productId;
    this.restaurantItemId = data.id;
    this.parent = data.parent;
    // this.freebiryaniCount = data.point;
    // console.log(this.freebiryaniCount);
    console.log(this.productId, "product id");

    // });
    if (this.productId) {
      const searchValue = +this.actRoute.snapshot.queryParams.fromSearch;
      console.log(searchValue);

      this.fromSearch = searchValue ? 1 : 0;
      this.getFreeBiryani();
      this.getProductDetails();
      this.restaurantOffline = this.utility.restaurantOffline;
      // this.checkForCartChanges();
    } else {
      this.router.navigate([HOME.fullUrl]);
    }
    this.gaService.event("ITEM_DETAIL_OPEN", {});
    this.gaService.event("view_item", {
      currency: "INR",
      value: this.itemDetails?.price?.price,
      items:[
        {
          item_id: this.itemDetails?.itemInfo?.itemId,
          item_name: this.itemDetails?.itemInfo?.itemName,
        }
      ]
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {}

  // checkForCartChanges() {
  //   this.sub.add(
  //     this.cartMessangerService.getMessage().subscribe((res) => {
  //       if (res) {
  //         this.getProductDetails();
  //       }
  //     })
  //   );
  // }
  async getProductDetails() {
    try {
      let { data } = await this.productListService.getProductDetails(
        this.utility.getRestaurantId(),
        this.productId,
        this.restaurantItemId,
        this.fromSearch
      );
      this.itemDetails = data;
      console.log(this.itemDetails);
    } catch (error) {
      this.router.navigate([HOME.fullUrl]);
    }

    (window as any).Moengage.track_event("Product_View", {
      "Product name": this.itemDetails?.itemInfo?.itemName,
      "Product ID": this.itemDetails?.itemInfo?.itemId, // string value
    });
    this.gaService.event("view_item", {
      currency: "INR",
      value: this.itemDetails?.price?.price,
      items:[
        {
          item_id: this.itemDetails?.itemInfo?.itemId,
          item_name: this.itemDetails?.itemInfo?.itemName,
        }
      ]
    });
    // LoggerService.log(data, "40");
  }
  async checkUserRole() {
    const userData = await this.profileService.getProfileDetail();
    if (userData.isGuestUser === 1) {
      this.isUserGuest = true;
      return true;
    }
    this.isUserGuest = false;
    return false;
  }
  async removeFromCart() {
    if (!this.isUserGuest) {
      const res = await this.checkUserRole();
      this.checkForFreeBiryani();
      let itemObj = {
        restaurantId: this.utility.getRestaurantId(),
        restaurantItemId: this.itemDetails.itemData._id,
        quantity: 1,
        freeBiryani: this.freeBiryani,
      };

      // if (this.parent == "home") {
      //   itemObj.restaurantItemId = this.itemDetails._id;
      // }

      const response = await this.productListService
        .removeItemToCart(itemObj)
        .then((res) => {
          if (res) {
            this.itemDetails.itemData.cartItemCount =
              this.itemDetails.itemData?.cartItemCount - 1;
            this.cartMessangerService.subject1.next(false);
            this.changeDone = true;
            // if (this.data.parent == "freeBiryani") {
            //   this.freebiryaniCount = this.freebiryaniCount + 1;
            // }
            // this.cartMessangerService.sendMessage(res.data);
          }
        })
        .catch((err) => {
          // error
        });

      (window as any).Moengage.track_event("Remove_from_Cart", {
        "Product ID": this.itemDetails?.itemData?.itemInfo?.itemId,
        "Product Name": this.itemDetails?.itemData?.itemInfo?.itemName,
        Price: this.itemDetails?.itemData?.price?.price,
        "Product image URL":
          this.itemDetails?.itemData?.itemInfo?.media?.mediaUrl,
      });
    }
  }

  async addItemToCart() {
    this.checkForFreeBiryani();
    const res = await this.checkUserRole();
    console.log(res);
    let itemObj = {
      restaurantId: this.utility.getRestaurantId(),
      restaurantItemId: this.itemDetails.itemData._id,
      quantity: 1,
      freeBiryani: this.freeBiryani,
    };

    // if (this.data.parent == "freeBiryani") {
    //   if (this.freebiryaniCount - 1 >= 0) {
    //     itemObj.freeBiryani = 1;
    //     const response = await this.productListService
    //       .addItemToCart(itemObj)
    //       .then((result) => {
    //         if (result) {
    //           this.itemDetails.itemData.cartItemCount =
    //             this.itemDetails.itemData?.cartItemCount + 1;
    //           this.cartMessangerService.subject1.next(true);
    //           this.changeDone = true;
    //           this.freebiryaniCount = this.freebiryaniCount - 1;
    //           // this.cartMessangerService.sendMessage(result.data);
    //         }
    //       })
    //       .catch((err) => {
    //         //error
    //         this.utility.showAlert(err.error.message);
    //       });
    //   } else {
    //     this.utility.showAlert("You have no free biryani points left");
    //   }
    // } else {
    const response = await this.productListService
      .addItemToCart(itemObj, this.parent)
      .then((result) => {
        if (result) {
          this.itemDetails.itemData.cartItemCount =
            this.itemDetails.itemData?.cartItemCount + 1;
          this.cartMessangerService.sendMessage({ data: result.data });
          this.cartMessangerService.subject1.next(true);
          this.changeDone = true;
          // this.cartMessangerService.sendMessage(result.data);
        }
      })
      .catch((err) => {
        //error
        this.utility.showAlert(err.error.message);
      });
    (window as any).Moengage.track_event("Add_To_Cart", {
      "Product ID": this.itemDetails.itemData?.itemInfo?.itemId, // string value
      "Product Name": this.itemDetails.itemData?.itemInfo?.itemName,
      Price: this.itemDetails.itemData?.price?.price,
      "Product image URL": this.itemDetails?.media?.mediaUrl, // string value        // string value        // string value
    });
    // }
  }

  checkForFreeBiryani() {
    if (this.freeBiryanis.length) {
      const freeBiryaniItem = this.freeBiryanis.find(
        (item) => item._id == this.itemDetails._id
      );
      this.freeBiryani =
        freeBiryaniItem && freeBiryaniItem.freeBiryani === true ? 1 : 0;
      console.log(this.freeBiryani);
    }
  }

  async getFreeBiryani() {
    const obj = {
      restaurantId: this.utility.getRestaurantId(),
      page: 1,
      limit: 100,
    };
    const res = await this.productListService.getFreeBiryani(obj);
    console.log(res);
    this.freeBiryanis = res.data.data.data;
  }

  goBack() {
    window.history.back();
  }

  async addToFavourite(itemId: string) {
    let restaurantId = this.utility.getRestaurantId();
    const userData = await this.profileService.getProfileDetail();
    if (userData.isGuestUser) {
      this.matDialogRef.close();
      this.utility.guestPopup();
    } else {
      this.productListService
        .addFavourite({ restaurantId, itemId })
        .then((data) => {
          console.log(this.itemDetails, data);
          this.itemDetails.itemData.isFavourite = 1;
          this.cartMessangerService.sendMessage(false);
          this.changeDone = true;
        })
        .catch((err) => {});
      (window as any).Moengage.track_event("Add_To_Favourite", {
        "Product ID": this.itemDetails.itemData.itemInfo.itemId, // string value
        "Product Name": this.itemDetails.itemData.itemInfo.itemName, // string value
      });
    }
  }

  removeFromFavourite(itemId: string) {
    let restaurantId = this.utility.getRestaurantId();
    this.productListService
      .removeFavourite({ restaurantId, itemId })
      .then((data) => {
        this.itemDetails.itemData.isFavourite = 0;

        this.cartMessangerService.sendMessage(false);
        this.changeDone = true;
      })
      .catch((err) => {});
  }

  closePopup() {
    this.matDialogRef.close(this.changeDone);
  }

  scrollLeft() {
    document.getElementById("add-lis").scrollTo({
      top: 0,
      left: document.getElementById("add-lis").scrollLeft - 400,
      behavior: "smooth",
    });
    this.scrollLeftCount = this.scrollLeftCount - 400;
    this.showScrollRight = true;
  }

  scrollRight() {
    document.getElementById("add-lis").scrollTo({
      top: 0,
      left: document.getElementById("add-lis").scrollLeft + 400,
      behavior: "smooth",
    });

    console.log(document.getElementById("add-lis").clientWidth);

    this.scrollLeftCount = this.scrollLeftCount + 400;
    console.log(
      this.scrollLeftCount,
      document.getElementById("add-lis").scrollWidth
    );

    if (
      this.scrollLeftCount >=
      document.getElementById("add-lis").scrollWidth -
        document.getElementById("add-lis").clientWidth
    ) {
      this.showScrollRight = false;
    }
  }
}
