import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "src/environments/environment";

declare let gtag: Function;
declare var FB: any;

@Injectable({ providedIn: "root" })
export class GoogleAnalyticsService {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (isPlatformBrowser(this.platformId)) {
      gtag("event", eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        //  eventValue: eventValue
      });
    }
  }

  public event(eventName: string, params: {}) {
    if (isPlatformBrowser(this.platformId)) {
      gtag("event", eventName, params);
      console.log(eventName, params)
      FB.AppEvents.logEvent(eventName);
    }
  }

  public init(events?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.listenForRouteChanges(events);
      try {
        (function (d, s, id) {
          let js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
        (window as any).fbAsyncInit = function () {
          FB.init({
            appId: environment.facebookKey, // CLient Accoutn Id
            cookie: true,
            xfbml: true,
            version: "v9.0",
          });
          FB.AppEvents.logPageView();
        };

        const script1 = document.createElement("script");
        script1.async = true;
        script1.src =
          "https://www.googletagmanager.com/gtag/js?id=" +
          environment.googleAnalyticsKey;
        document.head.appendChild(script1);

        const script2 = document.createElement("script");
        script2.innerHTML =
          `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` +
          environment.googleAnalyticsKey +
          `', {'send_page_view': false});
      `;
        document.head.appendChild(script2);
      } catch (ex) {
        console.error("Error appending google analytics");
        console.error(ex);
      }
    }
  }

  private listenForRouteChanges(events?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // console.log(event);
          gtag("config", environment.googleAnalyticsKey, {
            page_path: event.urlAfterRedirects,
          });
          if (events && events.eventName) {
            this.event(events.eventName, { eventAction: events.eventAction });
          }
          // console.log(
          //   "Sending Google Analytics hit for route",
          //   event.urlAfterRedirects
          // );
          // console.log("Property ID", environment.googleAnalyticsKey);
        }
      });
    }
  }
}
