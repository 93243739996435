import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { OrderNotificationService } from "../order-notification/order-notification.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-order-notification",
  templateUrl: "./order-notification.component.html",
  styleUrls: ["./order-notification.component.scss"],
})
export class OrderNotificationComponent implements OnInit, OnDestroy {
  open = true;
  notify: any;
  notificationData;
  private subscription: Subscription;
  notificationArray = [];

  constructor(
    private orderToast: OrderNotificationService,
    private _changeRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription = this.orderToast.notification.subscribe(
      (showState: any) => {
        showState["time"] = Date.now();
        this.notificationArray.push(showState);
        if (
          this.notificationArray.length &&
          this.notificationArray.length > 3
        ) {
          setTimeout(() => {
            this.notificationArray[0].class = "";
            this.notificationArray.splice(0, 1);
          }, 1000);
        }
        this.notificationArray.reverse();
        this.notify = showState;
        this.open = false;
        this.open = true;
        this._changeRef.detectChanges();
        setTimeout(() => {
          this.open = false;
          this.notify.class = "active";
        }, this.notify.time);
      }
    );
    this.orderToast.logout.subscribe((res) => {
      if (res === 1 && this.notificationArray.length) {
        for (let i = 0; i < this.notificationArray.length; i++) {
          this.removeNotify(this.notificationArray[i]);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  removeNotify(item) {
    const index = this.notificationArray.findIndex((x) => x === item);
    // this.notificationArray[index].class = '';
    this.notificationArray.splice(index, 1);
    this._changeRef.detectChanges();
    // setTimeout(() => {
    // }, 2000);
  }

  navigateToOrders(item) {
    // this.router.navigateByUrl('/orders/new_orders');
    this.removeNotify(item);
  }
}
