import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UtilityService } from "../../services/utility.service";
import { LOGIN, WELCOME } from "../../constant/routes";

@Injectable()
export class HomeGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _utilityService: UtilityService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log("can load >>>>> home");

    if (this._utilityService.getAuthToken()) {
      return true;
    }
    return this.navigate();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    console.log("home guard");

    if (this._utilityService.getAuthToken()) {
      // console.log("can load >>>>> home");

      return true;
    }
    return this.navigate();
  }
  navigate() {
    // this._utilityService.clearStorage();
    this._router.navigate([WELCOME.fullUrl]);
    return false;
  }
}
