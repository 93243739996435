<div class="popup-wrapper">
    <div class="popup-inner">
        <div class="product-image">
            <a (click)="closePopup()" class="close-popup"><img src="assets/images/ic_cross.svg" alt="Image" /></a>
            <div *ngIf="this.parent == 'freeBiryani'" class="free-icon">Loyalty Reward</div>
            <figure>
                <img [src]="itemDetails?.itemData?.itemInfo?.media?.mediaUrl"
                    onerror="this.src = 'assets/images/card-placeholder.jpg'" alt="Image" />
            </figure>
        </div>
        <div class="product-info">
            <div class="header">
                <div class="header-left">
                    <h3 style="white-space: normal;">{{itemDetails?.itemData?.itemInfo?.itemName}}</h3>
                    <span class="food_type">
                        <img *ngIf="itemDetails?.itemData?.itemInfo?.itemType == 0" src="assets/images/Nonveg_Icon.svg"
                            alt="">
                        <img *ngIf="itemDetails?.itemData?.itemInfo?.itemType == 1"
                            src="assets/images/Veg_Icon_sign.svg" alt="">
                    </span>
                </div>
                <ng-container *ngIf="this.parent != 'freeBiryani'">

                    <div class="header-right">
                        <span style="cursor: pointer;" class="fav_icon">
                            <img (click)="removeFromFavourite(itemDetails?.itemData?.itemInfo?.itemId)"
                                *ngIf="itemDetails?.itemData?.isFavourite == 1" src="assets/images/hearticon_Active.svg"
                                alt="Icon">
                            <img (click)="addToFavourite(itemDetails?.itemData?.itemInfo?.itemId)"
                                *ngIf="itemDetails?.itemData?.isFavourite == 0"
                                src="assets/images/hearticon_dark_Inactive.svg" alt="Icon">
                        </span>
                    </div>
                </ng-container>
            </div>
            <div class="product-price-info">
                <div class="price">
                    <h3>&#x20B9; {{itemDetails?.itemData?.price?.price | number: "1.2-2"}}</h3>
                    <!-- <p>inclusive of all taxes</p> -->
                </div>
                <ng-container *ngIf="this.parent != 'freeBiryani'">
                    <div class="add-more-item-wrap" *ngIf="!itemDetails?.itemData?.blockDeliverySales">
                        <div *ngIf="itemDetails?.itemData?.cartItemCount == 0" class="btn-wrap">
                            <button [disabled]="restaurantOffline" type="button" class="btn_primary"
                                (click)="addItemToCart()">+ Add
                                to Cart</button>
                        </div>
                        <div *ngIf="itemDetails?.itemData?.cartItemCount >0" class="count_wrapper">
                            <button [disabled]="restaurantOffline" class="minus_btn" (click)="removeFromCart()">
                                <img src="assets/images/minus_icon.svg" alt="Icon" />
                            </button>
                            <span class="vlaue">{{itemDetails?.itemData?.cartItemCount}}</span>
                            <button [disabled]="restaurantOffline" class="plus_btn" (click)="addItemToCart()">
                                <img src="assets/images/ic_plus.svg" alt="Icon" />
                            </button>
                        </div>
                    </div>
                    <div *ngIf="itemDetails?.itemData?.blockDeliverySales">
                        <p style="color: #f06554;">
                            Out of stock
                        </p>
                    </div>
                </ng-container>
            </div>
            <div class="description-wrap">
                <h4>Description</h4>
                <p>{{itemDetails?.itemData?.itemInfo?.itemDescription}}</p>

            </div>
            <div *ngIf="itemDetails?.suggestedData?.data.length" class="extra-addons">
                <div class="left">
                    <h3>Other dishes you may like</h3>
                </div>
                <div class="right">
                    <a *ngIf="scrollLeftCount" (click)="scrollLeft()" class="prev-icon"><img
                            src="assets/images/ic_scroll_left.svg" alt="Image" /></a>
                    <a *ngIf="showScrollRight" (click)="scrollRight()" class="next-icon"><img
                            src="assets/images/ic_scroll_right.svg" alt="Image" /></a>
                </div>
            </div>
            <div *ngIf="itemDetails?.suggestedData?.data.length" id="add-lis" class="addons-listing">
                <div *ngFor="let item of itemDetails?.suggestedData?.data || []" class="addon-outer">
                    <!-- <div  class="dishes-item"> -->


                    <app-menu-food-card [parent]="'detail'" [item]="item" [freeBiryanis]="freeBiryanis">
                    </app-menu-food-card>
                    <!-- </div> -->
                </div>

            </div>
        </div>
    </div>
</div>