import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UtilityService } from "src/app/services/utility.service";
import { LOCATION_SEARCH, HOME, LOGIN, WELCOME } from "src/app/constant/routes";
import { IMyCurrentLocation } from "src/app/constant/interface";
import { POPUP_MESSAGES } from "src/app/constant/messages";

@Injectable()
export class AddressGuard implements CanActivate, CanLoad {
  constructor(private utilityService: UtilityService, private router: Router) {}

  naviagtetoLocation() {
    this.router.navigate([WELCOME.fullUrl]);
    return false;
  }

  navigateToLogin() {
    this.router.navigate([LOGIN.fullUrl]);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // if (!this.utilityService.getUserAddress()) {
    //   console.log("not address=============");
    //   return this.naviagtetoLocation();
    // } else {
    //   const address = JSON.parse(this.utilityService.getUserAddress()).address;
    //   console.log("adresss", address);
    //   if (address.coordinates && address.coordinates.length) {
    //     if (this.utilityService.getAuthToken()) {
    //       console.log("adress && auth");
    //       return true;
    //     } else {
    //       console.log("nooooooooooooo");
    //       return this.navigateToLogin();
    //     }
    //   } else {
    //     return this.naviagtetoLocation();
    //   }
    // }
    if (
      this.utilityService.getRestaurantId() == undefined ||
      this.utilityService.getRestaurantId() == null
    ) {
      this.router.navigate([WELCOME.fullUrl]);
      return false;
    }
    return true;
    // return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // if (!this.utilityService.getUserAddress()) {
    //   console.log("not address=============");
    //   return this.naviagtetoLocation();
    // } else {
    //   const address = JSON.parse(this.utilityService.getUserAddress()).address;
    //   console.log("adresss", address);
    //   if (address.coordinates && address.coordinates.length) {
    //     if (this.utilityService.getAuthToken()) {
    //       console.log("adress && auth");
    //       return true;
    //     } else {
    //       console.log("nooooooooooooo");
    //       return this.navigateToLogin();
    //     }
    //   } else {
    //     console.log("locatuonn serororo");
    //     return this.naviagtetoLocation();
    //   }
    // }

    if (
      this.utilityService.getRestaurantId() == undefined ||
      this.utilityService.getRestaurantId() == null
    ) {
      this.router.navigate([WELCOME.fullUrl]);
      return false;
    }
    return true;

    // return true;
  }
}
