import { Injectable } from "@angular/core";
import { HttpService } from "src/app/services/http.service";
import {
  ADD_ITEM_TO_CART,
  FREE_BIRYANI,
  ADD_FAVOURITE_POST,
  REMOVE_FAVOURITE_DELETE,
  PRODUCT_DETAIL_GET,
} from "src/app/constant/urls";

@Injectable()
export class MenuFoodCardService {
  constructor(private _http: HttpService) {}

  addItemToCart(itemData,parent): Promise<any> {
    if(parent != "welcome"){
      return this._http
        .post(ADD_ITEM_TO_CART, itemData, { showLoader: false })
        .toPromise();
    }
  }

  removeItemToCart(itemData): Promise<any> {
    return this._http
      .deleteWithBody(ADD_ITEM_TO_CART, itemData, { showLoader: false })
      .toPromise();
  }

  addFavourite(payload: any): Promise<any> {
    return this._http.post(ADD_FAVOURITE_POST, payload).toPromise();
  }

  removeFavourite(payload: any): Promise<any> {
    return this._http
      .deleteWithBody(REMOVE_FAVOURITE_DELETE, payload)
      .toPromise();
  }

  getProductDetails(
    restaurantId: string,
    itemId: string,
    restaurantItemId: string,
    fromSearch: 0 | 1
  ): Promise<any> {
    return this._http
      .get(PRODUCT_DETAIL_GET(restaurantId, itemId, restaurantItemId, fromSearch))
      .toPromise();
  }

  getFreeBiryani(data) {
    return this._http.get(FREE_BIRYANI, data).toPromise();
  }
}
