import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "../../environments/environment";
import { LoaderService } from "./loader.service";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  bucket: S3;
  constructor(private loaderService: LoaderService, private _http: HttpService) { }
  async uploadFile(fileToUpload: File, loader = true) {
    try {
      const formData = new FormData();
      formData.append('avatar', fileToUpload);
      console.log(formData, "formData");
      const response = await this._http.post('admins/upload-to-s3/', formData).toPromise();
      console.log(response, "response");
      const data = response.data;
      const dataResponse = {
        'Location': data
      }
      return dataResponse;
    } catch (err) {
      if (loader) {
        this.loaderService.loader.next(false);
      }
    }
  }
  async uploadMultipleFiles(files) {
    try {
      this.loaderService.loader.next(true);
      const data = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < files.length; i++) {
        data.push(this.uploadFile(files[i], false));
      }
      const result = await Promise.all(data);
      this.loaderService.loader.next(false);
      return Promise.resolve(result);
    } catch (err) {
      this.loaderService.loader.next(false);
      return Promise.reject(err);
    }
  }
}
