import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { LoaderService } from "./loader.service";
import { HttpService } from "./http.service";
import { FCM_TOKEN_API } from "../constant/urls";
import { UtilityService } from "./utility.service";
import { OrderNotificationService } from "../modules/common/modules/order-notification/order-notification.service";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private _utilityService: UtilityService,
    private orderToast: OrderNotificationService,
    private _http: HttpService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.angularFireMessaging.messaging.subscribe((_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      });
    }
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    return;
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};
      data[userId] = token;
      this.angularFireDB.object("fcmTokens/").update(data);
    });
  }

  onUpdateDeviceToken(userId: string, token: string) {
    const authToken = this._utilityService.getAuthToken();
    if (token && authToken) {
      this.updateToken(userId, token);
      this._http.patch(FCM_TOKEN_API, { deviceToken: token }).toPromise();
    }
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    if (isPlatformBrowser(this.platformId)) {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          if (token) {
            localStorage.setItem("dtk", token);
            this.onUpdateDeviceToken(userId, token);
          }
        },
        (err) => {
          console.error("Unable to get permission to notify.", err);
        }
      );
    }
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    if (isPlatformBrowser(this.platformId)) {
      this.angularFireMessaging.messages.subscribe((payload) => {
        this.currentMessage.next(payload);
        console.log(payload, "payloadpayload");

        const data = payload["notification"];
        if (data) {
          this.orderToast.error(data.body, "active", data.title, 2000);
        }
      });
    }
  }
}
