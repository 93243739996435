import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UtilityService } from "src/app/services/utility.service";
import {
  HOME,
  LOCATION_SEARCH_MAP,
  LOGIN,
  WELCOME,
} from "src/app/constant/routes";

@Injectable()
export class LocationGuard implements CanActivate, CanLoad {
  constructor(private utility: UtilityService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // console.log("can activate >>>>> location");

    if (this.utility.getUserAddress() && !this.utility.fromSidebar) {
      this.navigateToLogin();
    }

    if (this.utility.getUserAddress() && this.utility.fromSidebar) {
      // this.navigateHome();
      this.router.navigate(["/"]);
    }
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.utility.getUserAddress() && !this.utility.fromSidebar) {
      this.navigateHome();
    }
    if (this.utility.getUserAddress() && this.utility.fromSidebar) {
      // console.log(this.utility.getUserAddress());
      // console.log("can load >>>>> location");
      // this.navigateHome();
      this.router.navigate(["/"]);
    }
    return true;
  }

  navigateToLogin() {
    this.router.navigate([LOGIN.fullUrl]);
    return true;
  }

  navigateHome() {
    this.router.navigate([LOCATION_SEARCH_MAP.fullUrl]);
    return true;
  }
}
