import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderNotificationService {

  logout = new BehaviorSubject<any>(null);

  private toast = new Subject<any>();
  thumbnail='';
  public notification = this.toast.asObservable();
  data = {
    title: 'New Order Recieved',
    msg: '',
    type: '',
    time: null, pic : this.thumbnail , class: '' };


  constructor() {
    this.thumbnail = '../../../assets/default-image/paradiseIcon.png';
  }

  success(message, openClass?, title?, time?: number) {
    this.data = {
      title,
      pic : this.thumbnail,
      msg: message,
      type: '_success',
      time: time ? time : 5000,
      class: openClass
    };
    if (message) {
      this.toast.next(this.data);
    }
  }

  info(message, title?, openClass?, time?: number) {
    this.data = {
      title,
      pic : this.thumbnail,
      msg: message,
      type: '_info',
      class: openClass,
      time: time ? time : 5000
    };
    if (message) {
      this.toast.next(this.data);
    }
  }

  warning(message, openClass?, title?, time?: number) {
    this.data = {
      title,
      pic : this.thumbnail,
      msg: message,
      type: '_warning',
      class: openClass,
      time: time ? time : 4000
    };
    if (message) {
      this.toast.next(this.data);
    }
  }

  error(message: string, openClass?, title?: string, time?: number) {
    this.data = {
      title,
      pic : this.thumbnail,
      msg: message,
      class: openClass,
      type: '_error',
      time: time ? time : 5000
    };
    if (message) {
      this.toast.next(this.data);
    }
  }
}
