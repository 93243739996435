import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { IPopupData, IPopupResponse } from "../models/popup";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { IMyCurrentLocation } from "../constant/interface";
import { ISearchAutocomplete } from "../models/search-autocomplete.interface";
import { ConfirmationModalComponent } from "../modules/common/components/confirmation-modal/confirmation-modal.component";
import { AnyCnameRecord } from "dns";
import { LOGIN, WEB_ACCOUNT, WEB_LOGIN } from "../constant/routes";
import { WebSidebarService } from "./web-sidebar.service";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  countryCode = new BehaviorSubject<string>("");
  userLocation = new BehaviorSubject<string>("");
  searchActive = new BehaviorSubject<boolean>(false);
  addressChanged = new BehaviorSubject<boolean>(false);
  noRestoMessage = new BehaviorSubject<string>("");

  searchData = new BehaviorSubject<string>("");
  cartUpdatedFromPopup = new BehaviorSubject(false);

  /**
   * if true, user is visiting from laptop or pc browser
   * if false then mobile browser
   */
  fromSidebar = false;
  restaurantOffline: boolean = false;
  cartPrice;
  firstLaunch: boolean = false;
  addressToEdit: any = null;
  webCardTabName: string = "cart";
  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private webSidebar: WebSidebarService
  ) {
    // console.log("came in utilit");
    var ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    )
      this.fromSidebar = false;
    else if (/Chrome/i.test(ua)) this.fromSidebar = true;
    else this.fromSidebar = true;
  }

  clearStorage() {
    localStorage.clear();
  }

  getAuthToken() {
    return localStorage.getItem(environment.tokenKey);
  }

  setToken(token) {
    localStorage.setItem(environment.tokenKey, token);
  }

  getGuestToken() {
    return localStorage.getItem(environment.guestKey);
  }

  setGuestToken(uuid) {
    localStorage.setItem(environment.guestKey, uuid);
  }

  checkRestaurantId(): boolean {
    const restaurantId = this.getRestaurantId();
    if (
      restaurantId == "" ||
      restaurantId == undefined ||
      restaurantId == "undefined" ||
      restaurantId == null ||
      restaurantId == "null"
    ) {
      return false;
    } else {
      return true;
    }
  }

  setUserAddress(address) {
    localStorage.setItem(environment.addressKey, JSON.stringify(address));
  }

  setUserLocalAddress(address) {
    localStorage.setItem(environment.localAddressKey, JSON.stringify(address));
  }
  getUserLocalAddress() {
    return localStorage.getItem(environment.localAddressKey);
  }

  getUserAddress() {
    return localStorage.getItem(environment.addressKey);
  }

  setRestaurantId(payload: any) {
    localStorage.setItem("restaurantId", payload.restaurantId);
  }

  getRestaurantId() {
    return localStorage.getItem("restaurantId");
  }

  getNoRestoMessage() {
    return localStorage.getItem("noRestoMessage");
  }

  setNoRestoMessage(payload: any) {
    localStorage.setItem("noRestoMessage", payload.message);
  }

  showAlert(message, duration = 3000) {
    this._snackBar.open(message, "Close", {
      duration,
    });
  }

  trim(data) {
    for (const item in data) {
      if (typeof data[item] === "string") {
        data[item] = data[item].trim();
      }
    }
    return data;
  }
  // errorAlert(error) {
  //   let data: IPopupData = {
  //     message:
  //       error && error.error && error.error.message
  //         ? error.error.message
  //         : TranslateService.data.SOMETHING_WENT_WRONG,
  //     cancelButtonText: TranslateService.data.POPUP_MESSAGES.CLOSE,
  //     hideConfirmButton: true,
  //   };
  //   this.openDialog(data).subscribe((success) => {});
  // }
  openDialog(data: IPopupData): Observable<IPopupResponse> {
    const dialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: "500px",
      data,
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  openDialogCopy(data: IPopupData) {
    const dialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: "500px",
      data,
      disableClose: true,
    });

    return dialogRef;
  }
  serialize(obj) {
    const str =
      "?" +
      Object.keys(obj)
        .reduce(function (a, k) {
          a.push(k + "=" + encodeURIComponent(obj[k]));
          return a;
        }, [])
        .join("&");
    return str;
  }

  findResult(results, name: Array<any>) {
    const result = results.find((obj) => this.arrayEquals(name, obj.types));
    // fore
    return result
      ? result.long_name || result.short_name
      : results.find((item) => item.types[0] === "route");
  }
  arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  async getCurrentLocation(locationObject?: ISearchAutocomplete) {
    try {
      let position: any;
      try {
        position = await this.getCurrentPosition();
        console.log(position);
      } catch (error) {
        console.log("this.getCurrentPosition()", error);
        return Promise.reject(error);
      }

      const fetchResponse = await fetch(
        // tslint:disable-next-line:max-line-length
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
          locationObject &&
          (locationObject.mapSearchActive || locationObject.latControl.value)
            ? locationObject.latControl.value
            : position.coords.latitude
        },${
          locationObject &&
          (locationObject.mapSearchActive || locationObject.longConntrol.value)
            ? locationObject.longConntrol.value
            : position.coords.longitude
        }&key=${environment.googleKey}`
      );

      const response = await fetchResponse.json();

      const results = response.results[0].address_components;

      const locationObj: IMyCurrentLocation = {
        city: this.findResult(results, ["locality", "postal_town"]),
        state: this.findResult(results, [
          "administrative_area_level_1",
          "administrative_area_level_2",
        ]),
        country: this.findResult(results, ["country"]),
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        address: position.formatted_address,
        pincode: this.findResult(results, ["postal_code"]),
      };

      return locationObj;
    } catch (error) {
      console.log("error in gettting user location", error);
      return error;
    }
  }

  getCurrentPosition = (options = {}) => {
    return new Promise((resolve, reject) => {
      // navigator.geolocation.getCurrentPosition(resolve, reject, options);
      let address = JSON.parse(this.getUserAddress());
      let coordinates = {
        coords: {
          latitude: address.address.coordinates[1],
          longitude: address.address.coordinates[0],
        },
      };
      resolve(coordinates);
    });
  };

  getRequiredQueryParam() {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    tomorrow.setHours(23);
    tomorrow.setMinutes(59);
    tomorrow.setSeconds(59);
    const params = {
      fromDate: new Date().toISOString(),
      toDate: tomorrow.toISOString(),
    };
    return params;
  }

  get currentLocation() {
    return JSON.parse(localStorage.getItem("paradise-address"));
  }

  guestPopup() {
    const dialogData: IPopupData = {
      message: "You are currently logged in as guest, Please login to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "OK",
    };
    const dialogRef = this.openDialog(dialogData);

    dialogRef.subscribe((yes) => {
      if (yes) {
        if (this.fromSidebar) {
          this.webSidebar.openSidebar();

          this.router.navigate(
            [
              {
                outlets: {
                  web_sidebar: [WEB_ACCOUNT.fullUrl, WEB_LOGIN.fullUrl],
                },
              },
            ],
            {
              skipLocationChange: true,
              // relativeTo: this.route.parent,
            }
          );
        } else {
          this.router.navigate([LOGIN.fullUrl]);
        }
      }
    });
  }
}
