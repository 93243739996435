<ng-container *ngFor="let notify of notificationArray">
  <div class="notification" [class.active]="notify?.class">
    <mat-icon class="popup-close" (click)="removeNotify(notify)">close</mat-icon>
    <div class="img">
      <!-- <img [src]="notify?.pic" /> -->
      <img src="assets/images/paradise-logo-small.svg" />
    </div>
      <div (click)="navigateToOrders(notify)" class="content">
        <div class="title">{{ notify?.title }}</div>
        <p class="message">{{ notify?.msg }}</p>
      <!-- <div class="message">{{notify?.time | date: 'h:mm:ss a'}}</div> -->
      </div>
  </div>
</ng-container>
