<div class="popup-wrapper">
    <div class="popup-inner" *ngIf="modalData?.title">
        <h1 mat-dialog-title>{{ modalData?.title }}</h1>
    </div>
    <mat-dialog-content>
        <div class="modal_message">
            <p>{{ modalData?.message }}</p>
            <div *ngIf="modalData?.showTextBox" mat-dialog-actionst>
                <h4 style="text-align: center; margin-top: 20px">
                    {{ "REASON_TO_ACTION" }}
                </h4>
                <mat-form-field>
                    <textarea matInput placeholder="Type here.." [(ngModel)]="responseData.note" name="note"></textarea>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="btn-wrapper">
            <div class="btn-wrap" *ngIf="!modalData?.hideCancelButton && !modalData?.hideConfirmButton">
                <button mat-stroked-button type="button" (click)="onNoClick()" class="modal-btn no outline-btn">
          {{ modalData?.cancelButtonText }}
        </button>
            </div>
            <div class="btn-wrap" *ngIf="modalData?.hideConfirmButton">
                <button mat-raised-button type="button" (click)="onNoClick()" class="modal-btn no outline-btn">
          {{ modalData?.cancelButtonText }}
        </button>
            </div>
            <div class="btn-wrap">
                <button mat-raised-button type="button" color="primary" class="btn-primary" [disabled]="
          (!responseData ||
            !responseData.note ||
            responseData.note.trim() == '') &&
          modalData?.showTextBox
        " [mat-dialog-close]="responseData" (click)="confirm()" cdkFocusInitial>
          {{ modalData?.confirmButtonText }}
        </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>