import { Injectable } from "@angular/core";
import { HttpService } from "src/app/services/http.service";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CartMessangerService {
  subject = new Subject();

  subject1 = new Subject();

  subject2 = new Subject();
  constructor(private _http: HttpService) {}

  sendMessage(product) {
    this.subject.next(product);
  }

  getMessage() {
    return this.subject.asObservable();
  }

  getAction() {
    return this.subject1.asObservable();
  }
}
