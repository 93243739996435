import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UserResolverService } from "./resolvers/user-resolver.service";
import {
  HOME,
  LOGIN,
  LOCATION_SEARCH,
  WELCOME,
  LOCATION_SEARCH_MAP,
  WEB_ACCOUNT,
  WEB_LOCATION_SEARCH,
  PRIVACY_POLICY,
  LINK
} from "./constant/routes";
import { AccountGuard } from "./guards/account/account.guard";
import { AddressGuard } from "./guards/address/address.guard";
import { HomeGuard } from "./guards/home/home.guard";
import { LocationGuard } from "./guards/location/location.guard";
import { FirstLaunchGuard } from "./guards/first-launch/first-launch.guard";

const routes: Routes = [
  {
    path: WELCOME.path, //open
    loadChildren: () =>
      //import("./modules/features/welcome-screen/welcome-screen.module").then(
      import("./modules/features/product-welcome/product-welcome.module").then(
        //(m) => m.WelcomeScreenModule
        (m) => m.ProductWelcomeModule
      ),
    canActivate: [LocationGuard],
    canLoad: [LocationGuard],
  },
  {
    path: LINK.path, //open
    loadChildren: () =>
      import("./modules/features/redirect-to-app/redirect-to-app.module").then(
        (m) => m.RedirectToAppModule
      ),
  },
  {
    path: LOCATION_SEARCH.path, //open
    loadChildren: () =>
      import("./modules/features/home-search/home-search.module").then(
        (m) => m.HomeSearchModule
      ),
  },
  {
    path: LOCATION_SEARCH_MAP.path, //open
    loadChildren: () =>
      import(
        "./modules/features/location-search-map/location-search-map.module"
      ).then((m) => m.LocationSearchMapModule),
  },
  {
    path: LOGIN.path,
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
    canLoad: [AccountGuard],
    canActivate: [AccountGuard],
  },
  {
    path: "web-account",
    loadChildren: () =>
      import("./modules/web-modules/web-account/web-account.module").then(
        (m) => m.WebAccountModule
      ),
    outlet: "web_sidebar",
  },
  {
    path: "web-sidebar",
    loadChildren: () =>
      import("./modules/web-modules/web-sidebar/web-sidebar.module").then(
        (m) => m.WebSidebarModule
      ),
    outlet: "web_sidebar",
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./company-information/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./company-information/terms/terms.module").then(
        (m) => m.TermsModule
      ),
  },

  {
    path: HOME.path, //open
    // pathMatch: "full",
    loadChildren: () =>
      import("./modules/features/features.module").then(
        (m) => m.FeaturesModule
      ),
    canLoad: [HomeGuard, AddressGuard],
    canActivate: [HomeGuard, AddressGuard],
    resolve: { userData: UserResolverService },
  },
  {
    path: "**",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: "reload",
    scrollPositionRestoration: "top",
    initialNavigation: 'enabled'
}),
  ],
  exports: [RouterModule],
  providers: [AccountGuard, AddressGuard, HomeGuard, LocationGuard],
})
export class AppRoutingModule {}
