<ng-container [ngSwitch]="parent" *ngIf="!itemDetails?.itemInCart">
  <ng-container *ngSwitchCase="'detail'">
    <div class="dishes-item">
      <div class="dish-image">
        <figure (click)="routeToDetails()">
          <img
            onerror="this.src = 'assets/images/card-placeholder.jpg'"
            [src]="itemDetails?.itemInfo?.media?.mediaUrl"
            alt="Image"
          />
        </figure>
        <span class="food_type">
          <!-- <img src="assets/images/Nonveg_Icon.svg" alt="">
                    <img src="assets/images/Veg_Icon_sign.svg" alt=""> -->
          <img
            *ngIf="itemDetails?.itemInfo?.itemType == 0"
            src="assets/images/Nonveg_Icon.svg"
            alt=""
          />
          <img
            *ngIf="itemDetails?.itemInfo?.itemType == 1"
            src="assets/images/Veg_Icon_sign.svg"
            alt=""
          />
        </span>
      </div>
      <div class="dish-info">
        <h3 (click)="routeToDetails()" class="dish-name">
          {{ itemDetails?.itemInfo?.itemName }}
        </h3>
        <p class="dish-price">
          &#x20B9; {{ itemDetails?.price?.price | number: "1.2-2" }}
        </p>
        <div class="add-btn" *ngIf="!itemDetails?.blockDeliverySales">
          <!-- <button type="button" class="outline-btn">Add</button> -->
          <button
            [disabled]="restaurantOffline"
            class="btn_empty"
            *ngIf="itemDetails?.cartItemCount === 0 || isFreeItem"
            (click)="addItemToCart()"
          >
            ADD
          </button>
          <div class="count_wrapper" *ngIf="itemDetails?.cartItemCount > 0">
            <button
              [disabled]="restaurantOffline"
              class="minus_btn"
              (click)="removeFromCart()"
            >
              <img
                [src]="
                  itemDetails?.cartItemCount > 1
                    ? 'assets/images/minus_icon.svg'
                    : 'assets/images/Bin.svg'
                "
                alt="Icon"
              />
            </button>
            <span class="vlaue">{{ itemDetails?.cartItemCount }}</span>
            <button
              [disabled]="restaurantOffline"
              class="plus_btn"
              (click)="addItemToCart()"
            >
              <img src="assets/images/ic_plus.svg" alt="Icon" />
            </button>
          </div>
        </div>
        <div class="add-btn" *ngIf="itemDetails?.blockDeliverySales">
          <p style="color: #f06554">Out of stock</p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'cart'">
    <div class="cart-item">
      <div class="card_main_wrapper-cart">
        <div class="card-main-inner">
          <figure class="food_img">
            <img
              onerror="this.src = 'assets/images/card-placeholder.jpg'"
              src="assets/images/account_banner.jpg"
              alt=""
            />
            <span class="food_type">
              <img src="assets/images/Nonveg_Icon.svg" alt="" />
              <img src="assets/images/Veg_Icon_sign.svg" alt="" />
            </span>
          </figure>
          <div class="desc_wrapper" style="place-self: stretch">
            <div class="desc_inner_wrapp">
              <div class="title-fav-wrap">
                <h3 class="title">Mutton Biryani Family</h3>
              </div>
              <p class="desc">
                This Family Pack consist of a portion of Special Mutton Biryani
                that comes packed...
              </p>
            </div>
          </div>
        </div>
        <div class="bottom_sec">
          <button class="btn_empty">ADD</button>
          <div class="count_wrapper">
            <button class="minus_btn">
              <img src="assets/images/Bin.svg" alt="Icon" />
            </button>
            <span class="vlaue">1</span>
            <button class="plus_btn">
              <img src="assets/images/ic_plus.svg" alt="Icon" />
            </button>
          </div>
          <p class="price">&#x20B9; 939</p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'welcome'">
    <div class="card_main_wrapper">
      <figure class="food_img">
        <img
          onerror="this.src = 'assets/images/card-placeholder.jpg'"
          [src]="itemDetails?.itemInfo?.media?.mediaUrl"
          [alt]="itemDetails?.itemInfo?.media?.altText"
        />
        <span class="food_type">
          <img
            *ngIf="itemDetails?.itemInfo?.itemType == 0"
            src="assets/images/Nonveg_Icon.svg"
            alt=""
          />
          <img
            *ngIf="itemDetails?.itemInfo?.itemType == 1"
            src="assets/images/Veg_Icon_sign.svg"
            alt=""
          />
        </span>
      </figure>
      <div class="desc_wrapper" style="place-self: stretch">
        <div class="desc_inner_wrapp">
          <div class="title-fav-wrap">
            <h3 class="title">
              {{ itemDetails?.itemInfo?.itemName }}
            </h3>
            <span class="fav_icon">
              <img
                (click)="removeFromFavourite(itemDetails?.itemInfo?.itemId)"
                *ngIf="itemDetails?.isFavourite == 1"
                src="assets/images/hearticon_Active.svg"
                alt=""
              />
              <img
                (click)="addToFavourite(itemDetails?.itemInfo?.itemId)"
                *ngIf="itemDetails?.isFavourite == 0"
                src="assets/images/hearticon_dark_Inactive.svg"
                alt=""
              />
            </span>
          </div>
          <p class="desc">
            {{ itemDetails?.itemInfo?.itemDescription }}
          </p>
        </div>
        <div class="bottom_sec">
          <ng-container *ngIf="!itemDetails?.blockDeliverySales">
            <p *ngIf="parent != 'freeBiryani'" class="price">
              &#x20B9; {{ itemDetails?.price?.price | number: "1.2-2" }}
            </p>
            <p *ngIf="parent == 'freeBiryani'" class="price"></p>

            <button
              [disabled]="restaurantOffline"
              [disabled]="restaurantOffline"
              class="btn_empty"
              *ngIf="itemDetails?.cartItemCount === 0"
              (click)="addItemToCart()"
            >
              ADD
            </button>
            <div class="count_wrapper" *ngIf="itemDetails?.cartItemCount > 0">
              <button
                [disabled]="restaurantOffline"
                class="minus_btn"
                (click)="removeFromCart()"
              >
                <img
                  [src]="
                    itemDetails?.cartItemCount > 1
                      ? 'assets/images/minus_icon.svg'
                      : 'assets/images/Bin.svg'
                  "
                  alt="Icon"
                />
              </button>
              <span class="vlaue">{{ itemDetails?.cartItemCount }}</span>
              <button
                [disabled]="restaurantOffline"
                class="plus_btn"
                (click)="addItemToCart()"
              >
                <img src="assets/images/ic_plus.svg" alt="Icon" />
              </button>
            </div>
          </ng-container>
          <p *ngIf="itemDetails?.blockDeliverySales" style="color: #f06554">
            Out of stock
          </p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="card_main_wrapper">
      <figure (click)="routeToDetails()" class="food_img">
        <img
          onerror="this.src = 'assets/images/card-placeholder.jpg'"
          [src]="itemDetails?.itemInfo?.media?.mediaUrl"
          [alt]="itemDetails?.itemInfo?.media?.altText"
        />
        <span class="food_type">
          <img
            *ngIf="itemDetails?.itemInfo?.itemType == 0"
            src="assets/images/Nonveg_Icon.svg"
            alt=""
          />
          <img
            *ngIf="itemDetails?.itemInfo?.itemType == 1"
            src="assets/images/Veg_Icon_sign.svg"
            alt=""
          />
        </span>
      </figure>
      <div class="desc_wrapper" style="place-self: stretch">
        <div class="desc_inner_wrapp">
          <div class="title-fav-wrap">
            <h3 (click)="routeToDetails()" class="title">
              {{ itemDetails?.itemInfo?.itemName }}
            </h3>
            <span class="fav_icon">
              <img
                (click)="removeFromFavourite(itemDetails?.itemInfo?.itemId)"
                *ngIf="itemDetails?.isFavourite == 1"
                src="assets/images/hearticon_Active.svg"
                alt=""
              />
              <img
                (click)="addToFavourite(itemDetails?.itemInfo?.itemId)"
                *ngIf="itemDetails?.isFavourite == 0"
                src="assets/images/hearticon_dark_Inactive.svg"
                alt=""
              />
            </span>
          </div>
          <p (click)="routeToDetails()" class="desc">
            {{ itemDetails?.itemInfo?.itemDescription }}
          </p>
        </div>
        <div class="bottom_sec">
          <ng-container *ngIf="!itemDetails?.blockDeliverySales">
            <p *ngIf="parent != 'freeBiryani'" class="price">
              &#x20B9; {{ itemDetails?.price?.price | number: "1.2-2" }}
            </p>
            <p *ngIf="parent == 'freeBiryani'" class="price"></p>

            <button
              [disabled]="restaurantOffline"
              [disabled]="restaurantOffline"
              class="btn_empty"
              *ngIf="itemDetails?.cartItemCount === 0"
              (click)="addItemToCart()"
            >
              ADD
            </button>
            <div class="count_wrapper" *ngIf="itemDetails?.cartItemCount > 0">
              <button
                [disabled]="restaurantOffline"
                class="minus_btn"
                (click)="removeFromCart()"
              >
                <img
                  [src]="
                    itemDetails?.cartItemCount > 1
                      ? 'assets/images/minus_icon.svg'
                      : 'assets/images/Bin.svg'
                  "
                  alt="Icon"
                />
              </button>
              <span class="vlaue">{{ itemDetails?.cartItemCount }}</span>
              <button
                [disabled]="restaurantOffline"
                class="plus_btn"
                (click)="addItemToCart()"
                *ngIf = "(itemDetails?.maxAvailable && itemDetails?.cartItemCount < itemDetails.maxAvailable) || !itemDetails?.maxAvailable"
              >
                <img src="assets/images/ic_plus.svg" alt="Icon" />
              </button>
            </div>
          </ng-container>
          <p *ngIf="itemDetails?.blockDeliverySales" style="color: #f06554">
            Out of stock
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
