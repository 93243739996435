import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MenuFoodCardComponent } from "./menu-food-card.component";
import { MenuFoodCardService } from "./service/menu-food-card.service";
import { MatDialogModule } from "@angular/material/dialog";
import { ItemDetailWebComponent } from "./item-detail-web/item-detail-web.component";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
import { ProductListService } from "src/app/modules/features/product-list/product-list.service";

@NgModule({
  declarations: [MenuFoodCardComponent],
  imports: [CommonModule],
  exports: [MenuFoodCardComponent],
  providers: [MenuFoodCardService, GoogleAnalyticsService],
})
export class MenuFoodCardModule {}
